<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="textarea-box">
      <h6>自定义打印提示文案</h6>
      <el-input class="textarea" type="textarea" :maxlength="1000" :rows="7"
                placeholder="请输入内容" v-model="content" show-word-limit></el-input>
      <el-button>取消</el-button>
      <el-button @click="handleSave" type="primary">保存</el-button>
    </div>
  </div>
</template>
<script>
import { savePrintSet, getContent } from '@/api/pms/accommodationSetting/printSet';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      crumbs: new Map([
          ['PMS'], ['住宿设置'], ['打印设置']
      ]),
      content: '',
      action: 'add'
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.getPrintSetCont()
  },
  methods: {
    // 保存打印设置
    handleSave() {
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId,
        content: this.content
      }
      this.action === 'edit' && (params.id = this.id)
      savePrintSet(this.action, params).then(({ success }) => {
        if (success) {
          this.getPrintSetCont()
          this.$message({ message: '保存成功！', type: 'success' })
        }
      })
    },
    // 获取打印设置
    getPrintSetCont() {
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId
      }
      getContent(params).then(({ success, records }) => {
        if (!success) return
        if (records.length > 0) {
          this.action = 'edit'
          this.id = records[0].id
          this.content = records[0].content
        } else {
          this.action = 'add'
          this.content = ''
        }
      })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getPrintSetCont()
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .textarea-box {
    background: #FFFFFF; padding: 30px;
    h6 { font-size: 18px; margin-bottom: 20px }
    .textarea { margin-bottom: 30px }
  }
}
</style>
